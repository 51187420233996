body {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    margin: 0;
}

.circles-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.circle {
    width: 20px;
    height: 20px;
    background-color: rgba(255, 0, 0, 0.5);
    /* Adjust circle styles as needed */
    border-radius: 50%;
    position: absolute;
}

.text-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.text-box {
    text-align: center;
    color: white;
    padding: 20px;
    max-width: 80%;
    background-color: rgba(255, 255, 255, 0.8);
    /* Semi-transparent white background */
    border-radius: 10px;
}

.text-box h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.text-box p {
    font-size: 16px;
    line-height: 1.6;
}