.service-box {
    transition: all 0.3s ease-in-out;
    color: lightgray;
    overflow: hidden;
    position: relative;
}

.service-box:hover {
    transform: translateY(-10px);
}

.service-box::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: inherit;
    /* Inherit the background color */
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 0;
}

.service-box:nth-child(1)::before {
    background: linear-gradient(to right, white, orange);
}

.service-box:nth-child(2)::before {
    background: linear-gradient(to right, white, red);
}

.service-box:nth-child(3)::before {
    background: linear-gradient(to right, white, green);
}

.service-box:nth-child(4)::before {
    background: linear-gradient(to right, white, blue);
}

.service-box:nth-child(5)::before {
    background: linear-gradient(to right, white, orange);
}

.service-box:nth-child(6)::before {
    background: linear-gradient(to right, white, red);
}

.service-box:nth-child(7)::before {
    background: linear-gradient(to right, white, green);
}

.service-box:nth-child(8)::before {
    background: linear-gradient(to right, white, blue);
}

.service-box:nth-child(9)::before {
    background: linear-gradient(to right, white, orange);
}

.service-box:nth-child(10)::before {
    background: linear-gradient(to right, white, red);
}

.service-box:nth-child(11)::before {
    background: linear-gradient(to right, white, green);
}

.service-box:nth-child(12)::before {
    background: linear-gradient(to right, white, blue);
}

.service-box:nth-child(2n)::before {
    transform: translateY(100%);
}

.service-box:nth-child(3n)::before {
    transform: translateX(-100%);
}

.service-box:nth-child(4n)::before {
    transform: translateX(100%);
}

.service-box:hover::before {
    transform: translateY(0);
    transform: translateX(0);
}

.service-box .group {
    position: relative;
    z-index: 1;
}

.service-box .group h3,
.service-box .group p,
.service-box .group svg,
.service-box .group .fa-icon {
    transition: color 0.3s ease-in-out;
}

.service-box:hover .group h3,
.service-box:hover .group p,
.service-box:hover .group svg,
.service-box:hover .group .fa-icon {
    color: white;
}

.transition-transform {
    transition: transform 3s ease;
}

.translate-y-10 {
    transform: translateY(10px);
}

.translate-y-0 {
    transform: translateY(0);
}

.group:hover .group-hover\:text-red-900 {
    color: #7f1d1d;
    /* Adjust to match your specific shade of red-900 */
}

.group:hover .group-hover\:opacity-90 {
    opacity: 0.9;
}

.service-box {
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.service-box:hover {
    transform: translateY(-10px);
    opacity: 1;
}

.service-box .group:hover .text-gray-600 {
    color: #b91c1c;
    /* Tailwind red-900 */
    opacity: 0.9;
}

.service-box .group:hover h3,
.service-box .group:hover svg {
    color: #b91c1c;
    /* Tailwind red-900 */
    opacity: 0.9;
}